@tailwind base;
@tailwind components;
@tailwind utilities;

html, 
body {
    height: 100%;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat/static/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}


html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background-color: #1A1B21;;
  color: white;
}

.section1 {
  background-image: url("./assets/images/section-1.png");
  background-repeat: no-repeat;
  background-position: center;
  mix-blend-mode: normal;
  background-size: cover;
  background-color: #1A1B21;
  /* box-shadow: inset 0 300px 300px 400px rgb(0, 0, 0, 0.5); */
}

.section2 {
  background-image: url("./assets/images/section-bg-img-2.png");
  background-repeat: no-repeat;
  background-position: center;
  mix-blend-mode: normal;
  background-size: cover;
  background-color: #1A1B21;
  /* box-shadow: inset 0 -300px 300px 400px rgb(0, 0, 0, 0.5); */
}


.gradient-text-color {
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(90.11deg, #FFF778 0.09%, #EE9A1B 27.13%, #9282EF 61.97%, #48C8E8 99.92%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
}

.uppercase {
  text-transform: uppercase;
}